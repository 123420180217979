import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "min-height": "100vh" }, attrs: { id: "allocations-bg" } },
    [
      _c("div", { staticClass: "allocation-container" }, [
        _c(
          "a",
          {
            staticClass: "allocation-header-back-page",
            on: {
              click: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          },
          [
            _c("font-awesome-icon", {
              staticClass: "allocation-header-back-page-icon",
              attrs: { icon: "fa-solid fa-arrow-left" },
            }),
            _c("span", { staticClass: "allocation-header-back-page-text" }, [
              _vm._v(_vm._s(_vm.$t("allocations"))),
            ]),
          ],
          1
        ),
        _vm.loading
          ? _c(
              "div",
              { staticClass: "item-center" },
              [
                _c(VProgressCircular, {
                  attrs: { indeterminate: "", size: "70", color: "primary" },
                }),
              ],
              1
            )
          : _c("div", [
              _c("div", { staticClass: "allocation-header" }, [
                _c("div", { staticClass: "allocation-card" }, [
                  _vm.roundDataLoading
                    ? _c(
                        "div",
                        { staticClass: "loading" },
                        [
                          _c(VProgressCircular, {
                            attrs: {
                              indeterminate: "",
                              size: "70",
                              color: "primary",
                            },
                          }),
                        ],
                        1
                      )
                    : _c("div", { staticClass: "allocation-round-container" }, [
                        _c("div", { staticClass: "allocaitons-round-header" }, [
                          _c("img", {
                            staticClass: "allocation-round-card-company-logo",
                            attrs: {
                              src: _vm.roundData.company.logo,
                              alt: "company logo",
                            },
                          }),
                          _c("div", { staticClass: "allocation-round-data" }, [
                            _c("span", { staticClass: "allocation-round-id" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("single_round") +
                                      " #" +
                                      _vm.roundData.roundId
                                  ) +
                                  " "
                              ),
                            ]),
                            _c(
                              "span",
                              { staticClass: "allocation-company-name" },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.roundData.company.name) + " "
                                ),
                              ]
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "round-details-allocations-header-status",
                                style:
                                  "color:" + _vm.selectOpportunityTextColor,
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass:
                                    "round-details-allocations-header-circle-icon",
                                  attrs: {
                                    color: _vm.statusCircleIconColor,
                                    icon: "fa-solid fa-circle",
                                  },
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t(_vm.selectOpportunityText)) +
                                    " "
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("div", [
                          _c(
                            "div",
                            { staticClass: "round-allocations-card-info" },
                            [
                              _c(
                                "span",
                                { staticClass: "round-allocations-card-title" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("total_allocations")) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "round-allocations-card-values",
                                },
                                _vm._l(
                                  _vm.roundData.totalInvested,
                                  function (invested, i) {
                                    return _c(
                                      "span",
                                      {
                                        key: i,
                                        staticClass:
                                          "round-allocations-card-value",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            invested.value
                                              ? _vm.formatToCurrency(
                                                  invested.currency,
                                                  invested.value
                                                )
                                              : "-"
                                          )
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "round-allocations-last-update",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("last_update_in", {
                                        date: _vm.lastUpdate,
                                      })
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                ]),
                _c("div", { staticClass: "allocation-card" }, [
                  _c(
                    "div",
                    { staticClass: "allocation-details-investor-info" },
                    [
                      _c("div", { staticClass: "investor-data" }, [
                        _c("span", { staticClass: "investor" }, [
                          _vm._v("Acionista"),
                        ]),
                        _c("span", { staticClass: "investor-name" }, [
                          _vm._v(_vm._s(_vm.allocationData.investorName)),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "investor-profile",
                            on: {
                              click: function ($event) {
                                return _vm.goToInvestorProfile(
                                  _vm.allocationData.investorId
                                )
                              },
                            },
                          },
                          [_vm._v("Ver perfil do investidor")]
                        ),
                      ]),
                      _c("div", { staticClass: "investor-data" }, [
                        _c("span", { staticClass: "allocation-value-label" }, [
                          _vm._v("Valor da alocação"),
                        ]),
                        _c("span", { staticClass: "allocation-value" }, [
                          _vm._v(
                            _vm._s(
                              _vm.formatToCurrency(
                                _vm.allocationData.currency,
                                _vm.allocationData.value
                              )
                            )
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c("span", { staticClass: "investor-last-update" }, [
                    _vm._v(
                      "Solicitação de alocação iniciada em " +
                        _vm._s(this.startedAllocation)
                    ),
                  ]),
                ]),
              ]),
              !_vm.loading
                ? _c("div", { staticClass: "allocation-details-container" }, [
                    _c("div", { staticClass: "allocation-details-header" }, [
                      _c(
                        "div",
                        { staticClass: "allocation-details-header-content" },
                        [
                          _c("span", { staticClass: "allocation-title" }, [
                            _vm._v(" Andamento da alocação "),
                          ]),
                          _c(
                            "span",
                            { staticClass: "allocation-last-update" },
                            [
                              _vm._v(
                                " Última atualização em " +
                                  _vm._s(
                                    _vm.formatDate(_vm.allocationData.updatedAt)
                                  ) +
                                  " por " +
                                  _vm._s(_vm.allocationData.updatedBy) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "btn-cancel-allocation" },
                        [
                          _c("NewDxaButton", {
                            attrs: {
                              title: _vm.$t("cancel_allocation"),
                              disabled: true,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "allocation-details-panels" },
                      [
                        _c(
                          VExpansionPanels,
                          { attrs: { id: "allocation-details-panels" } },
                          [
                            _c("AllocationRequest", {
                              attrs: {
                                allocationData: _vm.allocationData,
                                allocationId: _vm.allocationId,
                              },
                            }),
                            _c("AllocationAnalysis", {
                              attrs: {
                                allocationId: _vm.allocationId,
                                allocationData: _vm.allocationData,
                              },
                            }),
                            _c("AllocationFormalization", {
                              attrs: {
                                allocationId: _vm.allocationId,
                                allocationData: _vm.allocationData,
                                disabledComponent:
                                  _vm.allocationIsFormalization,
                              },
                            }),
                            _c("AllocationTransfer", {
                              attrs: {
                                allocationId: _vm.allocationId,
                                allocationData: _vm.allocationData,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }