import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VExpansionPanels,
    [
      _vm.allLoading
        ? _c(
            "div",
            { staticClass: "item-center mb-4" },
            [
              _c(VProgressCircular, {
                attrs: { indeterminate: "", size: "70", color: "primary" },
              }),
            ],
            1
          )
        : _c(
            VExpansionPanel,
            {
              class: {
                "background-transparent": _vm.checkAnalysisStatusTransparent(),
              },
            },
            [
              _c(
                VExpansionPanelHeader,
                { attrs: { id: "allocation-analysis-header" } },
                [
                  _c("div", { staticClass: "allocation-analysis-header" }, [
                    _c(
                      "h1",
                      {
                        staticClass: "allocation-analysis-header-title",
                        style:
                          _vm.checkAllocationDisabled() && "color: #AFAFAF",
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "allocation-analysis-header-title-icon",
                          attrs: {
                            icon:
                              "fa-solid " +
                              _vm.getAllocationStatusTextAndIcon().icon,
                            color:
                              _vm.getAllocationStatusTextAndIcon().iconColor,
                          },
                        }),
                        _vm._v(
                          " " + _vm._s(_vm.$t("allocation_of_analysis")) + " "
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "allocation-analysis-header-date-container",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "allocation-analysis-header-date-content",
                          },
                          [
                            _c(
                              "span",
                              {
                                style:
                                  _vm.checkAllocationDisabled() &&
                                  "color: #AFAFAF",
                              },
                              [_vm._v(_vm._s(_vm.$t("step_start")))]
                            ),
                            _vm.analysisStartDate
                              ? _c("span", { staticClass: "date" }, [
                                  _vm._v(_vm._s(_vm.analysisStartDate)),
                                ])
                              : _c("span", { staticClass: "date" }, [
                                  _vm._v("-"),
                                ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "allocation-analysis-header-date-content",
                          },
                          [
                            _c(
                              "span",
                              {
                                style:
                                  _vm.checkAllocationDisabled() &&
                                  "color: #AFAFAF",
                              },
                              [_vm._v(_vm._s(_vm.$t("step_completion")))]
                            ),
                            _vm.analysisEndDate
                              ? _c("span", { staticClass: "date" }, [
                                  _vm._v(_vm._s(_vm.analysisEndDate) + " "),
                                ])
                              : _c("span", { staticClass: "date" }, [
                                  _vm._v("-"),
                                ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "allocation-analysis-header-status" },
                    [
                      !_vm.checkAllocationDisabled()
                        ? _c(
                            "span",
                            {
                              staticClass: "allocation-analysis-status",
                              style:
                                "color: " +
                                _vm.getAllocationStatusTextAndIcon().color,
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      _vm.getAllocationStatusTextAndIcon().text
                                    ) + "."
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
              _c(VExpansionPanelContent, [
                _c(
                  "div",
                  {
                    staticClass: "allocation-analysis-content",
                    class: { "border-bottom": !_vm.checkAllocationDisabled() },
                  },
                  [
                    _vm.checkAllocationDisabled()
                      ? _c(
                          "span",
                          {
                            staticClass: "allocation-analysis-status",
                            style:
                              "color: " +
                              _vm.getAllocationStatusTextAndIcon().color,
                          },
                          [_vm._v(" Etapa não iniciada")]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "allocation-analysis-content-section-1" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "allocation-analysis-message-to-user",
                          },
                          [
                            _c("h2", [
                              _vm._v(_vm._s(_vm.$t("show_user_message_title"))),
                            ]),
                            _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("show_user_message_content")) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    !_vm.checkAllocationDisabled()
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "allocation-analysis-content-section-2",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "allocation-analysis-user-background-check",
                                class: {
                                  "border-bottom-none":
                                    _vm.allocationAnalysisStatus ===
                                      _vm.allocationStatusEnums.DENIED ||
                                    _vm.allocationAnalysisStatus ===
                                      _vm.allocationStatusEnums.CANCELLED,
                                },
                              },
                              [
                                _c("div", [
                                  _c(
                                    "h2",
                                    {
                                      staticClass:
                                        "allocation-analysis-section-title",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("user_background_check")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _vm.backgroundCheckData
                                    ? _c(
                                        "ul",
                                        {
                                          staticClass:
                                            "allocation-analysis-user-background-check-list",
                                        },
                                        [
                                          _c("li", [
                                            _c(
                                              "span",
                                              { staticClass: "label" },
                                              [_vm._v(_vm._s(_vm.$t("status")))]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "value" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.getBackgroundCheckStatus()
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("li", [
                                            _c(
                                              "span",
                                              { staticClass: "label" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("last_update"))
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "value" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getAllocationDateParsed(
                                                      _vm.backgroundCheckData
                                                        .LastUpdate
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("li", [
                                            _c(
                                              "span",
                                              { staticClass: "label" },
                                              [_vm._v(_vm._s(_vm.$t("result")))]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "value" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.getBackgroundCheckResult()
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "allocation-analysis-user-background-check-buttons-flex",
                                  },
                                  [
                                    !_vm.checkAnalysisStatusTransparent()
                                      ? _c(
                                          "div",
                                          [
                                            _c("NewDxaButton", {
                                              attrs: {
                                                outline: true,
                                                title: _vm.$t("update_status"),
                                                loading:
                                                  _vm.updateAllocationStatusLoading,
                                                disabled:
                                                  _vm.disableUpdateAllocationStatus,
                                              },
                                              on: {
                                                btnFunction:
                                                  _vm.handleClickUpdateAllocationStatus,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    !_vm.checkAnalysisStatusTransparent()
                                      ? _c(
                                          "div",
                                          [
                                            _c("NewDxaButton", {
                                              attrs: {
                                                outline: true,
                                                title:
                                                  _vm.$t("request_new_status"),
                                                loading:
                                                  _vm.requestNewAllocationStatusLoading,
                                                disabled:
                                                  _vm.disableRequestNewAllocationStatus,
                                              },
                                              on: {
                                                btnFunction:
                                                  _vm.handleClickRequestNewAllocationStatus,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    !_vm.checkAllocationDisabled()
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "allocation-analysis-content-section-3",
                          },
                          [
                            _vm.allocationAnalysisStatus !==
                              _vm.allocationStatusEnums.DENIED &&
                            _vm.allocationAnalysisStatus !==
                              _vm.allocationStatusEnums.CANCELLED
                              ? _c(
                                  "h2",
                                  {
                                    staticClass:
                                      "allocation-analysis-section-title",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("allocation_status")) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.checkAnalystisAllocationStatus()
                              ? _c("div", [
                                  _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "allocation-analysis-allocation-status-list",
                                    },
                                    [
                                      _c("li", [
                                        _c("span", { staticClass: "label" }, [
                                          _vm._v(_vm._s(_vm.$t("status"))),
                                        ]),
                                        _c("span", { staticClass: "value" }, [
                                          _vm._v(
                                            _vm._s(_vm.$tc("approved", 1))
                                          ),
                                        ]),
                                      ]),
                                      _c("li", [
                                        _c("span", { staticClass: "label" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("vehicle_title_single")
                                            )
                                          ),
                                        ]),
                                        _c("span", { staticClass: "value" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.allocationData
                                                .investmentVehicleName
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm.allocationAnalysisStatus ===
                            _vm.allocationStatusEnums.PLACEMENT_REVIEW
                              ? _c(
                                  VForm,
                                  {
                                    ref: "form",
                                    on: {
                                      submit: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.checkForm()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      VRadioGroup,
                                      {
                                        staticClass: "radio-group",
                                        attrs: { row: "" },
                                        on: {
                                          change: function (e) {
                                            return _vm.vehicleIsRequired(e)
                                          },
                                        },
                                        model: {
                                          value: _vm.allocationStatus,
                                          callback: function ($$v) {
                                            _vm.allocationStatus = $$v
                                          },
                                          expression: "allocationStatus",
                                        },
                                      },
                                      [
                                        _c(VRadio, {
                                          attrs: {
                                            color: "primary",
                                            value:
                                              _vm.allocationStatusEnums
                                                .FORMALIZATION,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "allocation-analysis-radiou-label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "approved_allocation"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            686851443
                                          ),
                                        }),
                                        _c(VRadio, {
                                          attrs: {
                                            color: "primary",
                                            value:
                                              _vm.allocationStatusEnums.DENIED,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "allocation-analysis-radiou-label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "denied_allocation"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            594067583
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "allocation-analysis-select-investment-vehicle",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "allocation-analysis-select-investment-input-container",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "input-label",
                                                class: {
                                                  "input-label-disabled":
                                                    _vm.disableInputSelectVehicle,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "select_investment_vehicle_to_allocation"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(VAutocomplete, {
                                              attrs: {
                                                disabled:
                                                  _vm.disableInputSelectVehicle,
                                                items: _vm.vehicleOptions,
                                                "item-text": "name",
                                                "item-value": "id",
                                                color: "primary",
                                                dense: "",
                                                label: _vm.$t("select"),
                                                outlined: "",
                                                rules:
                                                  _vm.vehicleInputIsRequired
                                                    ? [_vm.required]
                                                    : [],
                                                loading:
                                                  _vm.vehicleOptionsLoading,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "progress",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          VProgressCircular,
                                                          {
                                                            staticStyle: {
                                                              position:
                                                                "absolute",
                                                              right: "40px",
                                                              top: "10px",
                                                            },
                                                            attrs: {
                                                              indeterminate: "",
                                                              size: "20",
                                                              color: "primary",
                                                            },
                                                          }
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                false,
                                                2894075087
                                              ),
                                              model: {
                                                value: _vm.selectedVehicle,
                                                callback: function ($$v) {
                                                  _vm.selectedVehicle = $$v
                                                },
                                                expression: "selectedVehicle",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "allocation-analysis-select-investment-vehicle-button-save",
                                          },
                                          [
                                            _c("NewDxaButton", {
                                              attrs: {
                                                type: "submit",
                                                title: _vm.$t("save"),
                                                disabled:
                                                  _vm.disableButtonSaveVehicle,
                                                loading: _vm.submitLoading,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }