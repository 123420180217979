import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VExpansionPanels,
    [
      _vm.allLoading
        ? _c(
            "div",
            { staticClass: "item-center mb-4" },
            [
              _c(VProgressCircular, {
                attrs: { indeterminate: "", size: "70", color: "primary" },
              }),
            ],
            1
          )
        : _c(
            VExpansionPanel,
            {
              class: {
                "background-transparent":
                  _vm.checkFormalizationStatusTransparent(),
              },
            },
            [
              _c(
                VExpansionPanelHeader,
                { attrs: { id: "allocation-formalization-header" } },
                [
                  _c(
                    "div",
                    { staticClass: "allocation-formalization-header" },
                    [
                      _c(
                        "h1",
                        {
                          staticClass: "allocation-formalization-header-title",
                          style: _vm.disabledStep && "color: #AFAFAF",
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass:
                              "allocation-formalization-header-title-icon",
                            attrs: {
                              icon:
                                "fa-solid " +
                                _vm.getAllocationStatusTextAndIcon().icon,
                              color:
                                _vm.getAllocationStatusTextAndIcon().iconColor,
                            },
                          }),
                          _vm._v(" " + _vm._s(_vm.$t("formalization")) + " "),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "allocation-formalization-header-date-container",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "allocation-formalization-header-date-content",
                            },
                            [
                              _c(
                                "span",
                                { style: _vm.disabledStep && "color: #AFAFAF" },
                                [_vm._v(_vm._s(_vm.$t("step_start")))]
                              ),
                              _vm.formalizationStartDate
                                ? _c("span", { staticClass: "date" }, [
                                    _vm._v(_vm._s(_vm.formalizationStartDate)),
                                  ])
                                : _c("span", [_vm._v("-")]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "allocation-formalization-header-date-content",
                            },
                            [
                              _c(
                                "span",
                                { style: _vm.disabledStep && "color: #AFAFAF" },
                                [_vm._v(_vm._s(_vm.$t("step_completion")))]
                              ),
                              _vm.formalizationEndDate
                                ? _c("span", { staticClass: "date" }, [
                                    _vm._v(_vm._s(_vm.formalizationEndDate)),
                                  ])
                                : _c("span", { staticClass: "date" }, [
                                    _vm._v("-"),
                                  ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "allocation-formalization-header-status" },
                    [
                      !_vm.disabledStep
                        ? _c(
                            "span",
                            {
                              staticClass: "allocation-formalization-status",
                              style:
                                "color: " +
                                _vm.getAllocationStatusTextAndIcon().color,
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      _vm.getAllocationStatusTextAndIcon().text
                                    ) + "."
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
              _c(VExpansionPanelContent, [
                _c(
                  "div",
                  {
                    staticClass: "allocation-formalization-content",
                    class: {
                      "border-bottom": !_vm.disabledStep && _vm.vehicleData,
                    },
                  },
                  [
                    _vm.disabledStep
                      ? _c(
                          "span",
                          {
                            staticClass: "allocation-formalization-status",
                            style:
                              "color: " +
                              _vm.getAllocationStatusTextAndIcon().color,
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("step_not_started")))]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "allocation-formalization-message-to-user",
                      },
                      [
                        _c("h2", [
                          _vm._v(_vm._s(_vm.$t("show_user_message_title"))),
                        ]),
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("show_user_message_content")) +
                              " "
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
                !_vm.disabledStep && _vm.vehicleData
                  ? _c("div", [
                      _c(
                        "h2",
                        {
                          staticClass:
                            "allocation-formalization-vehicle-documents-title",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("vehicle_documents", {
                                  vehicle:
                                    _vm.allocationData.investmentVehicleName,
                                })
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _vm.vehicleData.closingType ==
                      _vm.vehicleClosingOptionsEnum.CLOSING_SA
                        ? _c("div", { staticClass: "documents-to-download" }, [
                            _c(
                              "div",
                              {
                                staticClass: "file-section border-bottom",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDownloadDocuments(
                                      _vm.vehicleData.bylaws,
                                      _vm.AllocationFormalizationFilledLoading
                                        .BYLAWS
                                    )
                                  },
                                },
                              },
                              [
                                _vm.filledLoading.loading &&
                                _vm.filledLoading.filled ===
                                  _vm.AllocationFormalizationFilledLoading
                                    .BYLAWS
                                  ? _c(VProgressCircular, {
                                      attrs: {
                                        indeterminate: "",
                                        size: "20",
                                        color: "primary",
                                      },
                                    })
                                  : _c("font-awesome-icon", {
                                      staticClass:
                                        "icon-success icon-download cursor-pointer",
                                      class: {
                                        disabled:
                                          !_vm.vehicleData.shareholderAgreement,
                                      },
                                      attrs: { icon: "fa-solid fa-download" },
                                    }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "document-name cursor-pointer",
                                    class: {
                                      disabled: !_vm.vehicleData.bylaws,
                                    },
                                    style:
                                      "" +
                                      (_vm.filledLoading.loading &&
                                        _vm.filledLoading.filled ===
                                          _vm
                                            .AllocationFormalizationFilledLoading
                                            .BYLAWS &&
                                        "cursor: wait"),
                                  },
                                  [_vm._v(_vm._s(_vm.$t("bylaws")))]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "file-section border-bottom",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDownloadDocuments(
                                      _vm.vehicleData.shareholderAgreement,
                                      _vm.AllocationFormalizationFilledLoading
                                        .SHARE_HOLDER_AGREEMENT
                                    )
                                  },
                                },
                              },
                              [
                                _vm.filledLoading.loading &&
                                _vm.filledLoading.filled ===
                                  _vm.AllocationFormalizationFilledLoading
                                    .SHARE_HOLDER_AGREEMENT
                                  ? _c(VProgressCircular, {
                                      attrs: {
                                        indeterminate: "",
                                        size: "20",
                                        color: "primary",
                                      },
                                    })
                                  : _c("font-awesome-icon", {
                                      staticClass:
                                        "icon-success icon-download cursor-pointer",
                                      class: {
                                        disabled:
                                          !_vm.vehicleData.shareholderAgreement,
                                      },
                                      attrs: { icon: "fa-solid fa-download" },
                                    }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "document-name cursor-pointer",
                                    class: {
                                      disabled:
                                        !_vm.vehicleData.shareholderAgreement,
                                    },
                                    style:
                                      _vm.filledLoading.loading &&
                                      _vm.filledLoading.filled ===
                                        _vm.AllocationFormalizationFilledLoading
                                          .SHARE_HOLDER_AGREEMENT &&
                                      "cursor: wait",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("shareholders_agreement")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "h2",
                        {
                          staticClass:
                            "allocation-formalization-vehicle-documents-title allocation-formalization-vehicle-documents-title-flex",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("formalization_documents")) +
                              " "
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "allocation-formalization-vehicle-documents-title-date",
                            },
                            [_vm._v(_vm._s(_vm.$t("sign_date")))]
                          ),
                        ]
                      ),
                      _vm.vehicleData.closingType ==
                      _vm.vehicleClosingOptionsEnum.CLOSING_SA
                        ? _c("div", { staticClass: "documents-to-download" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "file-section file-section-flex border-bottom",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "cursor-pointer",
                                    class: {
                                      disabled:
                                        _vm.allocationFormalizationStatus ===
                                          _vm.AllocationFormalizationEnum
                                            .WAITING_USER_FORMALIZATION ||
                                        !_vm.formalizationDocuments
                                          .AdhesionTermSaved,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.downloadAllocationSignedDocument(
                                          _vm
                                            .AllocationFormalizationFilledLoading
                                            .ADHESION_TERM
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm.filledLoading.loading &&
                                    _vm.filledLoading.filled ===
                                      _vm.AllocationFormalizationFilledLoading
                                        .ADHESION_TERM
                                      ? _c(VProgressCircular, {
                                          attrs: {
                                            indeterminate: "",
                                            size: "20",
                                            color: "primary",
                                          },
                                        })
                                      : _c("font-awesome-icon", {
                                          staticClass:
                                            "icon-success icon-download cursor-pointer",
                                          attrs: {
                                            icon: "fa-solid fa-download",
                                          },
                                        }),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "document-name",
                                        style:
                                          _vm.filledLoading.loading &&
                                          _vm.filledLoading.filled ===
                                            _vm
                                              .AllocationFormalizationFilledLoading
                                              .ADHESION_TERM &&
                                          "cursor: wait",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "round_closing_adhesion_term"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm.allocationFormalizationStatus ===
                                _vm.AllocationFormalizationEnum
                                  .WAITING_USER_FORMALIZATION
                                  ? _c(
                                      "span",
                                      { staticClass: "waiting-user-label" },
                                      [_vm._v(_vm._s(_vm.$t("wainting_user")))]
                                    )
                                  : _c("span", { staticClass: "date-label" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getAllocationDateParsed(
                                            _vm.formalizationDocuments
                                              .AdhesionTermSignatureDate,
                                            false
                                          )
                                        )
                                      ),
                                    ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "file-section file-section-flex" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "cursor-pointer",
                                    class: {
                                      disabled:
                                        _vm.allocationFormalizationStatus ===
                                          _vm.AllocationFormalizationEnum
                                            .WAITING_USER_FORMALIZATION ||
                                        !_vm.formalizationDocuments
                                          .NewsletterSubscriptionSaved,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.downloadAllocationSignedDocument(
                                          _vm
                                            .AllocationFormalizationFilledLoading
                                            .NEWS_LETTER_SUBSCRIPTION
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm.filledLoading.loading &&
                                    _vm.filledLoading.filled ===
                                      _vm.AllocationFormalizationFilledLoading
                                        .NEWS_LETTER_SUBSCRIPTION
                                      ? _c(VProgressCircular, {
                                          attrs: {
                                            indeterminate: "",
                                            size: "20",
                                            color: "primary",
                                          },
                                        })
                                      : _c("font-awesome-icon", {
                                          staticClass:
                                            "icon-success icon-download cursor-pointer",
                                          attrs: {
                                            icon: "fa-solid fa-download",
                                          },
                                        }),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "document-name",
                                        style:
                                          _vm.filledLoading.loading &&
                                          _vm.filledLoading.filled ===
                                            _vm
                                              .AllocationFormalizationFilledLoading
                                              .NEWS_LETTER_SUBSCRIPTION &&
                                          "cursor: wait",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("newsletter_subscription")
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm.allocationFormalizationStatus ===
                                _vm.AllocationFormalizationEnum
                                  .WAITING_USER_FORMALIZATION
                                  ? _c(
                                      "span",
                                      { staticClass: "waiting-user-label" },
                                      [_vm._v(_vm._s(_vm.$t("wainting_user")))]
                                    )
                                  : _c("span", { staticClass: "date-label" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getAllocationDateParsed(
                                            _vm.formalizationDocuments
                                              .NewsletterSignatureDate,
                                            false
                                          )
                                        )
                                      ),
                                    ]),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm.vehicleData.closingType ==
                      _vm.vehicleClosingOptionsEnum.CLOSING_LLC
                        ? _c("div", { staticClass: "documents-to-download" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "file-section file-section-flex border-bottom",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "cursor-pointer",
                                    class: {
                                      disabled:
                                        _vm.allocationFormalizationStatus ===
                                          _vm.AllocationFormalizationEnum
                                            .WAITING_USER_FORMALIZATION ||
                                        !_vm.formalizationDocuments
                                          .SubscriptionAgreementSaved,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.downloadAllocationSignedDocument(
                                          _vm
                                            .AllocationFormalizationFilledLoading
                                            .SUBSCRIPTION_AGREEMENT
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm.filledLoading.loading &&
                                    _vm.filledLoading.filled ===
                                      _vm.AllocationFormalizationFilledLoading
                                        .SUBSCRIPTION_AGREEMENT
                                      ? _c(VProgressCircular, {
                                          attrs: {
                                            indeterminate: "",
                                            size: "20",
                                            color: "primary",
                                          },
                                        })
                                      : _c("font-awesome-icon", {
                                          staticClass:
                                            "icon-success icon-download cursor-pointer",
                                          attrs: {
                                            icon: "fa-solid fa-download",
                                          },
                                        }),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "document-name",
                                        style:
                                          _vm.filledLoading.loading &&
                                          _vm.filledLoading.filled ===
                                            _vm
                                              .AllocationFormalizationFilledLoading
                                              .SUBSCRIPTION_AGREEMENT &&
                                          "cursor: wait",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "round_closing_subscription_agreement"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm.allocationFormalizationStatus ===
                                _vm.AllocationFormalizationEnum
                                  .WAITING_USER_FORMALIZATION
                                  ? _c(
                                      "span",
                                      { staticClass: "waiting-user-label" },
                                      [_vm._v(_vm._s(_vm.$t("wainting_user")))]
                                    )
                                  : _c("span", { staticClass: "date-label" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getAllocationDateParsed(
                                            _vm.formalizationDocuments
                                              .SubscriptionAgreementSignatureDate,
                                            false
                                          )
                                        )
                                      ),
                                    ]),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm.vehicleData.closingType ==
                      _vm.vehicleClosingOptionsEnum.NO_CLOSING
                        ? _c("div", { staticClass: "documents-to-download" }, [
                            _c("span", { staticClass: "date" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("no_formalization_documents")) +
                                  " "
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }