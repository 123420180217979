import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VExpansionPanels,
    [
      _vm.allLoading
        ? _c(
            "div",
            { staticClass: "item-center" },
            [
              _c(VProgressCircular, {
                attrs: { indeterminate: "", size: "70", color: "primary" },
              }),
            ],
            1
          )
        : _c(
            VExpansionPanel,
            {
              class: {
                "background-transparent":
                  _vm.checkTransferStatusTransparent() ||
                  _vm.disabledButtonConfirmTransfer(),
              },
              style: !_vm.checkAllocationDisabled() && "padding-bottom: 40px",
            },
            [
              _c(
                VExpansionPanelHeader,
                { attrs: { id: "allocation-transfer-header" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "allocation-transfer-header",
                      class: {
                        "allocation-transfer-header-mb":
                          _vm.getAllocationStatusTextAndIcon().marginBottom,
                      },
                    },
                    [
                      _c(
                        "h1",
                        {
                          staticClass: "allocation-transfer-header-title",
                          style:
                            _vm.checkAllocationDisabled() && "color: #AFAFAF",
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass:
                              "allocation-transfer-header-title-icon",
                            attrs: {
                              icon:
                                "fa-solid " +
                                _vm.getAllocationStatusTextAndIcon().icon,
                              color:
                                _vm.getAllocationStatusTextAndIcon().iconColor,
                            },
                          }),
                          _vm._v(" " + _vm._s(_vm.$t("transfer")) + " "),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "allocation-transfer-header-date-container",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "allocation-transfer-header-date-content",
                            },
                            [
                              _c(
                                "span",
                                {
                                  style:
                                    _vm.checkAllocationDisabled() &&
                                    "color: #AFAFAF",
                                },
                                [_vm._v(_vm._s(_vm.$t("step_start")))]
                              ),
                              _vm.transferStartDate
                                ? _c("span", { staticClass: "date" }, [
                                    _vm._v(_vm._s(_vm.transferStartDate)),
                                  ])
                                : _c("span", { staticClass: "date" }, [
                                    _vm._v("-"),
                                  ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "allocation-transfer-header-date-content",
                            },
                            [
                              _c(
                                "span",
                                {
                                  style:
                                    _vm.checkAllocationDisabled() &&
                                    "color: #AFAFAF",
                                },
                                [_vm._v(_vm._s(_vm.$t("step_completion")))]
                              ),
                              _vm.transferEndDate
                                ? _c("span", { staticClass: "date" }, [
                                    _vm._v(_vm._s(_vm.transferEndDate)),
                                  ])
                                : _c("span", { staticClass: "date" }, [
                                    _vm._v("-"),
                                  ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  !_vm.checkAllocationDisabled()
                    ? _c(
                        "div",
                        { staticClass: "allocation-transfer-header-status" },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "allocation-transfer-status",
                              style:
                                "color: " +
                                _vm.getAllocationStatusTextAndIcon().color,
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      _vm.getAllocationStatusTextAndIcon().text
                                    ) + "."
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          !_vm.checkAllocationDisabled() &&
                          !_vm.checkTransferStatusTransparent()
                            ? _c(
                                "span",
                                { staticClass: "allocation-transfer-status" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "allocation_transfer_message_alert_1"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _c(VExpansionPanelContent, [
                _vm.checkAllocationDisabled()
                  ? _c("div", {}, [
                      _c(
                        "span",
                        {
                          staticClass: "allocation-transfer-status",
                          style:
                            "color: " +
                            _vm.getAllocationStatusTextAndIcon().color,
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  _vm.getAllocationStatusTextAndIcon().text
                                ) + "."
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "allocation-transfer-content",
                    style: _vm.checkAllocationDisabled() && "margin-top: 0px",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "allocation-transfer-message-to-user" },
                      [
                        _c("h2", [
                          _vm._v(_vm._s(_vm.$t("show_user_message_title"))),
                        ]),
                        _vm.checkTransferStatusTransparent()
                          ? _c("div", [
                              _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "allocation_transfer_message_alert_2"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        !_vm.checkTransferStatusTransparent()
                          ? _c("div", [
                              _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "allocation_transfer_message_user_1"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "allocation_transfer_message_user_2"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
                !_vm.checkAllocationDisabled()
                  ? _c(
                      "div",
                      {
                        staticClass: "allocation-transfer-bank-data-container",
                        style:
                          _vm.checkTransferStatusTransparent() &&
                          "background-color: var(--white)",
                      },
                      [
                        _c(
                          "ul",
                          { staticClass: "allocation-transfer-bank-data-list" },
                          [
                            _c("li", { staticClass: "column-1" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "item-center cursor-pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.gs.copy_url_to_clipboard(
                                        _vm.allocationData.value
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["fas", "copy"] },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "allocation-transfer-back-label-copy",
                                    },
                                    [_vm._v(_vm._s(_vm.$t("copy")))]
                                  ),
                                ],
                                1
                              ),
                              _c("div", [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("investment_value_title"))
                                  ),
                                ]),
                                _c("span", { staticClass: "value" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatCurrency(
                                        _vm.allocationData.currency,
                                        _vm.allocationData.value
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("li", { staticClass: "column-1" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "item-center cursor-pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.gs.copy_url_to_clipboard(
                                        _vm.allocationData.investmentVehicleCnpj
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["fas", "copy"] },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "allocation-transfer-back-label-copy",
                                    },
                                    [_vm._v(_vm._s(_vm.$t("copy")))]
                                  ),
                                ],
                                1
                              ),
                              _c("div", [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v(_vm._s(_vm.$t("holder"))),
                                ]),
                                _c("span", { staticClass: "value" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.allocationData.investmentVehicleName
                                    )
                                  ),
                                ]),
                                _c("span", { staticClass: "value cnpj" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatCnpj(
                                        _vm.allocationData.investmentVehicleCnpj
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm.vehicleAccount && _vm.vehicleAccount.bank
                              ? _c("li", { staticClass: "column-1" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "item-center cursor-pointer",
                                      on: {
                                        click: function ($event) {
                                          _vm.gs.copy_url_to_clipboard(
                                            _vm.getVehicleBankName()
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["fas", "copy"] },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "allocation-transfer-back-label-copy",
                                        },
                                        [_vm._v(_vm._s(_vm.$t("copy")))]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("div", [
                                    _c("span", { staticClass: "label" }, [
                                      _vm._v(_vm._s(_vm.$t("bank_title"))),
                                    ]),
                                    _c("span", { staticClass: "value" }, [
                                      _vm._v(_vm._s(_vm.getVehicleBankName())),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.vehicleAccount && _vm.vehicleAccount.agency
                              ? _c("li", { staticClass: "column-2 row-1" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "item-center cursor-pointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.gs.copy_url_to_clipboard(
                                            _vm.vehicleAccount.agency
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["fas", "copy"] },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "allocation-transfer-back-label-copy",
                                        },
                                        [_vm._v(_vm._s(_vm.$t("copy")))]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("div", [
                                    _c("span", { staticClass: "label" }, [
                                      _vm._v(_vm._s(_vm.$t("agency"))),
                                    ]),
                                    _c("span", { staticClass: "value" }, [
                                      _vm._v(_vm._s(_vm.vehicleAccount.agency)),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.vehicleAccount && _vm.vehicleAccount.account
                              ? _c("li", { staticClass: "column-2 row-2" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "item-center cursor-pointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.gs.copy_url_to_clipboard(
                                            _vm.vehicleAccount.account
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["fas", "copy"] },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "allocation-transfer-back-label-copy",
                                        },
                                        [_vm._v(_vm._s(_vm.$t("copy")))]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("div", [
                                    _c("span", { staticClass: "label" }, [
                                      _vm._v(_vm._s(_vm.$t("account_title"))),
                                    ]),
                                    _c("span", { staticClass: "value" }, [
                                      _vm._v(
                                        _vm._s(_vm.vehicleAccount.account)
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            !_vm.checkTransferStatusTransparent() &&
                            _vm.vehicleAccount &&
                            _vm.vehicleAccount.pix
                              ? _c("li", { staticClass: "column-2 row-3" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "item-center cursor-pointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.gs.copy_url_to_clipboard(
                                            _vm.vehicleAccount.pix
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["fas", "copy"] },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "allocation-transfer-back-label-copy",
                                        },
                                        [_vm._v(_vm._s(_vm.$t("copy")))]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("div", [
                                    _c("span", { staticClass: "label" }, [
                                      _vm._v(_vm._s(_vm.$t("pix"))),
                                    ]),
                                    _c("span", { staticClass: "value" }, [
                                      _vm._v(_vm._s(_vm.vehicleAccount.pix)),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.checkTransferStatusTransparent()
                              ? _c("li", { staticClass: "column-2 row-3" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "item-center cursor-pointer icon-disabled",
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["fas", "copy"] },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                !_vm.checkAllocationDisabled()
                  ? _c(
                      "div",
                      {
                        staticClass: "allocation-transfer-register flex",
                        style:
                          _vm.checkTransferStatusTransparent() &&
                          "border-bottom: none; border-top: none",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "allocation-transfer-register-section-1",
                          },
                          [
                            _c(
                              "h2",
                              {
                                staticClass:
                                  "allocation-transfer-register-title",
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("transfer")) + " ")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex allocation-transfer-register-personal-data",
                              },
                              [
                                _c("div", [
                                  _c("span", { staticClass: "label" }, [
                                    _vm._v(_vm._s(_vm.$t("shareholder"))),
                                  ]),
                                  _c("span", { staticClass: "value" }, [
                                    _vm._v(_vm._s(_vm.investorInfo.fullName)),
                                  ]),
                                ]),
                                _c("div", [
                                  _c("span", { staticClass: "label" }, [
                                    _vm._v(_vm._s(_vm.$t("cpf"))),
                                  ]),
                                  _c("span", { staticClass: "value" }, [
                                    _vm._v(
                                      _vm._s(_vm.investorInfo.socialNumber)
                                    ),
                                  ]),
                                ]),
                                _vm.checkTransferStatusTransparent()
                                  ? _c("div", [
                                      _c("span", { staticClass: "label" }, [
                                        _vm._v(_vm._s(_vm.$t("transfer_date"))),
                                      ]),
                                      _c("span", { staticClass: "value" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getAllocationDateParsed(
                                              _vm.allocationData.depositedDate,
                                              false
                                            )
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            !_vm.checkTransferStatusTransparent()
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "allocation-transfer-input-date-container",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "allocation-transfer-input-date-label",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("extract_date_transfer")
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "form",
                                      [
                                        _c(
                                          VMenu,
                                          {
                                            ref: "MenuDateRef",
                                            attrs: {
                                              "close-on-content-click": false,
                                              transition: "scale-transition",
                                              "offset-y": "",
                                              "min-width": "auto",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        VTextField,
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "mask",
                                                                  rawName:
                                                                    "v-mask",
                                                                  value: [
                                                                    "##/##/####",
                                                                  ],
                                                                  expression:
                                                                    "['##/##/####']",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "allocation-transfer-input-date",
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                color:
                                                                  "#AAAAAA",
                                                                "background-color":
                                                                  "white",
                                                                dense: "",
                                                                outlined: "",
                                                                "append-icon":
                                                                  "mdi-calendar",
                                                                label:
                                                                  "DD/MM/AA",
                                                                height: "34",
                                                                rules: [
                                                                  _vm.required,
                                                                ],
                                                              },
                                                              on: {
                                                                blur: function (
                                                                  $event
                                                                ) {
                                                                  _vm.allocationDate =
                                                                    _vm.formatDateOnBlur(
                                                                      _vm.allocationMenuDateText
                                                                    )
                                                                },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.allocationMenuDateText,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.allocationMenuDateText =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "allocationMenuDateText",
                                                              },
                                                            },
                                                            "v-text-field",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        )
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3480579410
                                            ),
                                            model: {
                                              value: _vm.allocationMenuDate,
                                              callback: function ($$v) {
                                                _vm.allocationMenuDate = $$v
                                              },
                                              expression: "allocationMenuDate",
                                            },
                                          },
                                          [
                                            _c(VDatePicker, {
                                              attrs: {
                                                locale: "pt-BR",
                                                color: "primary",
                                                min: "1850-01-01",
                                              },
                                              on: {
                                                change:
                                                  _vm.saveAllocationMenuDate,
                                              },
                                              model: {
                                                value: _vm.allocationDate,
                                                callback: function ($$v) {
                                                  _vm.allocationDate = $$v
                                                },
                                                expression: "allocationDate",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                        !_vm.checkTransferStatusTransparent()
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "allocation-transfer-register-section-2",
                              },
                              [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("investor_not_confirm_transfer")
                                    )
                                  ),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "max-content allocation-transfer-register-section-2-button",
                                  },
                                  [
                                    _c("NewDxaButton", {
                                      attrs: {
                                        loading: _vm.submitLoading,
                                        title: _vm.$t("confirm_transfer"),
                                        disabled:
                                          _vm.disabledButtonConfirmTransfer(),
                                      },
                                      on: {
                                        btnFunction: _vm.handleConfirmTransfer,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                !_vm.checkAllocationDisabled() &&
                !_vm.checkTransferStatusTransparent()
                  ? _c(
                      "div",
                      { staticClass: "allocation-transfer-register-footer" },
                      [
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("request_new_transfer_title")) +
                              " "
                          ),
                        ]),
                        _c("span", { staticClass: "link" }, [
                          _vm._v(_vm._s(_vm.$t("request_new_transfer"))),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }