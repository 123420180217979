import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.allLoading
    ? _c(
        "div",
        { staticClass: "item-center mb-4" },
        [
          _c(VProgressCircular, {
            attrs: { indeterminate: "", size: "70", color: "primary" },
          }),
        ],
        1
      )
    : _c(
        VExpansionPanel,
        {
          staticClass: "allocation-panel",
          class: {
            "background-transparent": _vm.checkFormalizationStatusTransparent(),
          },
        },
        [
          _c(
            VExpansionPanelHeader,
            {
              class: { disabled: _vm.loading },
              attrs: { disabled: _vm.loading },
            },
            [
              _c("div", { staticClass: "allocation-panel-header" }, [
                _c("div", { staticClass: "allocation-panel-title" }, [
                  _c(
                    "div",
                    [
                      _c("font-awesome-icon", {
                        staticClass: "allocation-analysis-header-title-icon",
                        attrs: {
                          color: _vm.getAllocationStatusTextAndIcon().iconColor,
                          icon:
                            "fa-solid " +
                            _vm.getAllocationStatusTextAndIcon().icon,
                        },
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "allocation-request-title",
                          class: { "disabled-color": _vm.loading },
                        },
                        [_vm._v("Solicitação da alocação")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "allocation-request-status",
                      style:
                        "color: " +
                        _vm.getAllocationStatusTextAndIcon().textColor,
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.getAllocationStatusTextAndIcon().text)
                        )
                      ),
                    ]
                  ),
                  _vm.allocationRequestData.HasPreviousAllocation
                    ? _c("div", [
                        _c("span", { staticClass: "has-previous-disclaimer" }, [
                          _vm._v(
                            "Entre em contato com o investidor para organizar o processo de unificar a alocação."
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "allocation-request-dates" }, [
                  _c("div", { staticClass: "request-step" }, [
                    _c(
                      "span",
                      {
                        staticClass: "step-label",
                        class: { "disabled-color": _vm.loading },
                      },
                      [_vm._v(_vm._s(_vm.$t("step_start")))]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "step-date",
                        class: { "disabled-color": _vm.loading },
                      },
                      [_vm._v(_vm._s(_vm.requestStartDate))]
                    ),
                  ]),
                  _c("div", { staticClass: "request-step" }, [
                    _c(
                      "span",
                      {
                        staticClass: "step-label",
                        class: { "disabled-color": _vm.loading },
                      },
                      [_vm._v(_vm._s(_vm.$t("step_completion")))]
                    ),
                    _vm.requestEndDate
                      ? _c(
                          "span",
                          {
                            staticClass: "step-date",
                            class: { "disabled-color": _vm.loading },
                          },
                          [_vm._v(_vm._s(_vm.requestEndDate))]
                        )
                      : _c("span", { staticClass: "no-data" }, [_vm._v("-")]),
                  ]),
                ]),
              ]),
            ]
          ),
          _c(VExpansionPanelContent, [
            _c("div", { staticClass: "separator" }),
            _c("div", { staticClass: "documents-section" }, [
              _c("div", { staticClass: "documents-section-header" }, [
                _c("span", { staticClass: "documents" }, [
                  _vm._v("Documentos"),
                ]),
                _c("span", { staticClass: "signature" }, [
                  _vm._v(" Data de assinatura"),
                ]),
              ]),
              _c("div", { staticClass: "document-row" }, [
                _c(
                  "div",
                  {
                    staticClass: "file-section",
                    on: {
                      click: function ($event) {
                        return _vm.downloadFile(
                          _vm.allocationRequestData.commitmentTermFileId,
                          _vm.AllocationRequestFilesEnums.COMMITMENT_TERM,
                          _vm.allocationRequestData.commitmentTermSigned
                        )
                      },
                    },
                  },
                  [
                    _vm.fileDownloadLoading.loading &&
                    _vm.fileDownloadLoading.file ===
                      _vm.AllocationRequestFilesEnums.COMMITMENT_TERM
                      ? _c(VProgressCircular, {
                          attrs: {
                            indeterminate: "",
                            size: "20",
                            color: "primary",
                          },
                        })
                      : _c("font-awesome-icon", {
                          staticClass: "icon-success icon-download",
                          class: {
                            "disabled-color":
                              !_vm.allocationRequestData.commitmentTermSigned,
                          },
                          attrs: { icon: "fa-solid fa-download" },
                        }),
                    _c(
                      "span",
                      {
                        staticClass: "document-name",
                        class: {
                          "disabled-color":
                            !_vm.allocationRequestData.commitmentTermSigned,
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("commitment_term")))]
                    ),
                  ],
                  1
                ),
                _vm.allocationRequestData.commitmentTermSigned
                  ? _c("span", { staticClass: "document-signature-date" }, [
                      _vm._v(
                        _vm._s(
                          _vm.getAllocationDateParsed(
                            _vm.allocationRequestData.commitmentTermSignedDate
                          )
                        )
                      ),
                    ])
                  : _c("span", [_vm._v("- ")]),
              ]),
              _c("div", { staticClass: "separator" }),
              _c("div", { staticClass: "document-row" }, [
                _c(
                  "div",
                  {
                    staticClass: "file-section",
                    on: {
                      click: function ($event) {
                        return _vm.downloadFile(
                          _vm.allocationRequestData.investmentProxyFileId,
                          _vm.AllocationRequestFilesEnums.INVESTMENT_PROXY,
                          _vm.allocationRequestData.signedInvestmentProxy
                        )
                      },
                    },
                  },
                  [
                    _vm.fileDownloadLoading.loading &&
                    _vm.fileDownloadLoading.file ===
                      _vm.AllocationRequestFilesEnums.INVESTMENT_PROXY
                      ? _c(VProgressCircular, {
                          attrs: {
                            indeterminate: "",
                            size: "20",
                            color: "primary",
                          },
                        })
                      : _c("font-awesome-icon", {
                          staticClass: "icon-success icon-download",
                          class: {
                            "disabled-color":
                              !_vm.allocationRequestData.signedInvestmentProxy,
                          },
                          attrs: { icon: "fa-solid fa-download" },
                        }),
                    _c(
                      "span",
                      {
                        staticClass: "document-name",
                        class: {
                          "disabled-color":
                            !_vm.allocationRequestData.signedInvestmentProxy,
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("proxy")))]
                    ),
                  ],
                  1
                ),
                _vm.allocationRequestData.signedInvestmentProxy
                  ? _c("span", { staticClass: "document-signature-date" }, [
                      _vm._v(
                        _vm._s(
                          _vm.getAllocationDateParsed(
                            _vm.allocationRequestData.signedInvestmentProxyDate
                          )
                        )
                      ),
                    ])
                  : _c("span", [_vm._v("-")]),
              ]),
            ]),
          ]),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }